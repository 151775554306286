import React, { useState } from 'react';
import { useApiMutation } from '../../../shared/hooks';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Select,
  Textarea,
  useDisclosure,
  VStack,
  Box,
} from '@chakra-ui/react';
import { emailCleanup, translateLevelToSwedish } from '../../../shared/helpers';
import { InfoIcon } from '@chakra-ui/icons';
import { Alert, MailToLink } from '../../ui';
import { getValidationMessages } from './utils';
import { WarningDialog } from './WarningDialog';
import {
  getWarningMessage,
  isRegistrationDisabled,
} from '../../../shared/helpers/isRegistrationDisabled';

export function RegistrationForm() {
  const [errors, setErrors] = useState({});
  const [payload, setPayload] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  const cancelRef = React.useRef();
  const {
    isOpen: isWarningOpen,
    onOpen: onWarningOpen,
    onClose: onWarningClose,
  } = useDisclosure();
  const [doMutation, { success, loading, error }] =
    useApiMutation('/api/register');
  const [inputs, setInputs] = useState({});

  const hasError = (field) => !!errors[field];
  const getError = (field) => errors[field];

  const onInput = (event) => {
    const { name, value, validationMessage } = event.target;
    //const isValid = input.checkValidity;
    setErrors({ ...errors, [name]: validationMessage });
    setInputs({ ...inputs, [name]: value });
  };

  const selectedLevel = inputs['level'];
  const disableAllForm = false;
  const dancerType = inputs['dancerType'];
  const partialDisabled = isRegistrationDisabled({ selectedLevel, dancerType });
  const showWarning = partialDisabled;
  const showFullMessage = false;
  const fullMessage = `Fullsatt ${translateLevelToSwedish(
    selectedLevel
  )}!!! Skicka mail om du vill komma i reservlistan.`;

  const warningMessage = getWarningMessage({ selectedLevel });

  const onSubmit = (ev) => {
    ev.preventDefault();
    const formEl = ev.target;
    const isValid = formEl.checkValidity(); //
    const formData = new FormData(formEl);
    const bodyData = Object.fromEntries(formData);
    const email = emailCleanup(bodyData?.email || '');
    const formPayload = { ...bodyData, email };
    const validationMessages = getValidationMessages({ formEl, formData });
    setErrors(validationMessages);
    setPayload(formPayload);

    if (isValid && !showFullMessage) {
      if (showWarning) {
        onWarningOpen();
      } else {
        doMutation(formPayload);
      }
    }

    // https://itnext.io/back-to-the-browser-form-validation-d32dd01802c0
  };

  return (
    <VStack
      align="stretch"
      as="form"
      method="post"
      onSubmit={onSubmit}
      noValidate
    >
      <Heading>Intresseanmälan</Heading>
      <FormControl
        isRequired
        isInvalid={hasError('level')}
        isDisabled={disableAllForm}
      >
        <FormLabel>Det krävs en intresseanmälan för varje person.</FormLabel>
        <Flex>
          <Select placeholder="Välj Nivå" name="level" onInput={onInput}>
            <option value="level1">Nybörjare</option>
            <option value="level2">Fortsättning</option>
            <option value="level3">Avancerad</option>
            <option value="level4">Nivå 4</option>
          </Select>
          <IconButton
            ml={1}
            aria-label="Open info modal"
            onClick={onOpen}
            icon={<InfoIcon />}
          />
        </Flex>
        {showWarning && (
          <Box mt={1}>
            <Alert status={'error'}>{warningMessage}</Alert>
          </Box>
        )}
        {showFullMessage && (
          <Box mt={1}>
            <Alert status={'info'}>{fullMessage}</Alert>
          </Box>
        )}
        <FormErrorMessage>{getError('level')}</FormErrorMessage>
      </FormControl>
      <FormControl
        isRequired
        isInvalid={hasError('dancerType')}
        isDisabled={disableAllForm}
      >
        <FormLabel>Jag vill dansa som:</FormLabel>
        <RadioGroup
          w="100%"
          name="dancerType"
          onInput={onInput}
          isDisabled={disableAllForm}
        >
          <VStack w="100%" align="stretch">
            <Radio colorScheme="green" value="follower" isRequired>
              {`Följare`}
            </Radio>
            <Radio colorScheme="green" value="lead" isRequired>
              {`Förare (lead)`}
            </Radio>
          </VStack>
        </RadioGroup>
        <FormErrorMessage>{getError('dancerType')}</FormErrorMessage>
      </FormControl>
      <FormControl
        isRequired
        isInvalid={hasError('fullname')}
        isDisabled={disableAllForm}
      >
        <FormLabel>Ditt förnamn och efternamn</FormLabel>
        <Input name="fullname" onInput={onInput} />
        <FormErrorMessage>{getError('fullname')}</FormErrorMessage>
      </FormControl>
      <FormControl
        isRequired
        isInvalid={hasError('email')}
        isDisabled={disableAllForm}
      >
        <FormLabel>Ditt mailadress</FormLabel>
        <Input
          name="email"
          pattern="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$"
          onInput={onInput}
        />
        <FormErrorMessage>{getError('email')}</FormErrorMessage>
      </FormControl>
      <FormControl isDisabled={disableAllForm}>
        <FormLabel>Meddelande</FormLabel>
        <Textarea name="message" />
      </FormControl>
      <Button
        colorScheme="green"
        type="submit"
        w="100%"
        isLoading={loading}
        hidden={success}
        isDisabled={disableAllForm || partialDisabled}
      >
        Skicka
      </Button>
      {success && (
        <Alert>
          Tack för att du visar intresse att vara med på MO Salsa. Du kommer att
          få ett bekräftelsemail med mer information. Om du inte har fått
          bekräftelsemailet nu direkt eller om du har andra frågor, skicka ett
          meddelande till <MailToLink />
        </Alert>
      )}
      {error && <Alert status="error">{error.message}</Alert>}
      <WarningDialog
        isWarningOpen={isWarningOpen}
        onWarningClose={onWarningClose}
        cancelRef={cancelRef}
        onConfirm={() => {
          doMutation(payload);
          onWarningClose();
        }}
      >
        <Box mt={1}>{warningMessage}</Box>
      </WarningDialog>
    </VStack>
  );
}
